import * as locationTypes from './LocationTypes';

const Locations = {
    [locationTypes.ENCINITAS.id] : {
        name: locationTypes.ENCINITAS.name,
        address: "477 N El Camino Real",	
        suite: "A200",
        city: "Encinitas",
        state: "CA",
        zip: "92024",
        country: "United States",	
        country_iso: "US",	
        lat: 33.057133,	
        lng: -117.261168,
        phone: "858-766-6007",
        accounts:{
            labCorp: '#04099060',
            Quest: '#92024183',
            Sharp: 'TBD',
        },
        myChartID: locationTypes.ENCINITAS.id
    },
    [locationTypes.LA_MESA.id] : {
        name: locationTypes.LA_MESA.name,
        address: "6386 Alvarado Ct",	
        suite: "#101",
        city: "San Diego",
        state: "CA",
        zip: "92120",
        country: "United States",	
        country_iso: "US",	
        lat: 32.776164,	
        lng: -117.060238,
        phone: "858-900-9490",
        accounts:{
            labCorp: '#04099130',
            Quest: '#92120118',
            Sharp: 'TBD',
        },
        myChartID: locationTypes.LA_MESA.id
    },					
    [locationTypes.DEL_MAR.id] : {
        name: locationTypes.DEL_MAR.name,
        address: "12843 El Camino Real",	
        suite: "#203",
        city: "San Diego",
        state: "CA",
        zip: "92130",
        country: "United States",	
        country_iso: "US",	
        lat: 32.949939,	
        lng: -117.233973,
        phone: "858-558-0399",
        accounts:{
            labCorp: '#04095810',
            Quest: '#92130043',
            Sharp: '#42265',
        },
        myChartID: locationTypes.DEL_MAR.id
    },					
    [locationTypes.DOWNTOWN_SAN_DIEGO.id] : {
        name: locationTypes.DOWNTOWN_SAN_DIEGO.name,
        address: "555 W C St.",	
        suite: "#102",
        city: "San Diego",
        state: "CA",
        zip: "92101",
        country: "United States",	
        country_iso: "US",	
        lat: 32.716501,	
        lng: -117.167926,
        phone: "858-333-4977",
        accounts:{
            labCorp: '#04077390',
            Quest: '#92101049',
            Sharp: '#92101049',
        },
        myChartID: locationTypes.DOWNTOWN_SAN_DIEGO.id
    },
    [locationTypes.CHULA_VISTA.id] : {
        name: locationTypes.CHULA_VISTA.name,
        address: "299 J St",	
        suite: "#102",
        city: "Chula Vista",
        state: "CA",
        zip: "91910",
        country: "United States",	
        country_iso: "US",	
        lat: 32.627839,	
        lng: -117.074166,
        phone: "858-779-2366",
        accounts:{
            labCorp: '#04072980',
            Quest: '#91910098',
            Sharp: '#42264',
        },
        myChartID: locationTypes.CHULA_VISTA.id
    },					
    [locationTypes.LA_JOLLA_DOWNTOWN.id]:{
        name: locationTypes.LA_JOLLA_DOWNTOWN.name,
        address: "7855 Ivanhoe Ave",	
        suite: "#110",
        city: "La Jolla",
        state: "CA",
        zip: "92037",
        country: "United States",	
        country_iso: "US",	
        lat: 32.847252,	
        lng: -117.271714,
        phone: "858-869-0188",
        accounts:{
            labCorp: '#04061280',
            Quest: '#92037166',
            Sharp: '#42268',
        },
        myChartID: locationTypes.LA_JOLLA_DOWNTOWN.id
    },					
    [locationTypes.CARLSBAD.id] : {
        name: locationTypes.CARLSBAD.name,
        address: "1905 Calle Barcelona",	
        suite: "#211",
        city: "Carlsbad",
        state: "CA",
        zip: "92009",
        country: "United States",	
        country_iso: "US",	
        lat: 33.070689,	
        lng: -117.266067,
        phone: "858-799-0933",
        accounts:{
            labCorp: '#04057360',
            Quest: '#92009010',
            Sharp: '#42263',
        },
        myChartID: locationTypes.CARLSBAD.id
    },					
    [locationTypes.HILLCREST.id]:{
        name: locationTypes.HILLCREST.name,
        address: "3900 Fifth Ave",	
        suite: "#110",
        city: "San Diego",
        state: "CA",
        zip: "92103",
        country: "United States",	
        country_iso: "US",	
        lat: 32.74855,	
        lng: -117.160895,
        phone: "858-554-1212",
        accounts:{
            labCorp: '#04004750',
            Quest: '#92103554',
            Sharp: '#22345',
        },
        myChartID: locationTypes.HILLCREST.id
    },					
    [locationTypes.CLAIREMONT.id] : {
        name: locationTypes.CLAIREMONT.name,
        address: "3737 Moraga Ave",	
        suite: "#B-103",
        city: "San Diego",
        state: "CA",
        zip: "92117",
        country: "United States",	
        country_iso: "US",	
        lat: 32.809258,	
        lng: -117.210677,
        phone: "858-799-0855",
        accounts:{
            labCorp: '#04011820',
            Quest: '#92117737',
            Sharp: '#22280',
        },
        myChartID: locationTypes.CLAIREMONT.id
    },					
    [locationTypes.KENSINGTON.id] : {
        name: locationTypes.KENSINGTON.name,
        address: "4142 Adams Ave",	
        suite: "#102",
        city: "San Diego",
        state: "CA",
        zip: "92116",
        country: "United States",	
        country_iso: "US",	
        lat: 32.763542,	
        lng: -117.106011,
        phone: "858-617-0717",
        accounts:{
            labCorp: '#04049060',
            Quest: '#04049060',
            Sharp: '#42267',
        },
        myChartID: locationTypes.KENSINGTON.id
    },
    [locationTypes.LA_JOLLA_XIMED.id] : {
        name: locationTypes.LA_JOLLA_XIMED.name,
        address: "9850 Genesee Ave",	
        suite: "#320",
        city: "La Jolla",
        state: "CA",
        zip: "92037",
        country: "United States",	
        country_iso: "US",	
        lat: 32.883369,	
        lng: -117.226549,
        phone: "858-864-9800",
        accounts:{
            labCorp: '#04147570',
            Quest: '#92037143',
            Sharp: '#22279',
        },
        myChartID: locationTypes.LA_JOLLA_XIMED.id
    }												
}

export default Locations;
