import * as locationTypes from './LocationTypes';

const PrimaryCareProviders = [

    {
        firstName: 'Carly',
        lastName: 'Babis',
        DEA: 'MB4470198',
        license: 'PA54747',
        NPI:'1679090625',
        gender: 'female',
        ageLimitPrimary: 18,
        ageLimitUrgentCare: 18,
        language: {},
        specialInterests: ['Internal Medicine'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: '',
            internalMedicine: true,
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: '',
            suturePlacement: false,
            sutureRemoval: true,
            IUDPlacement: 'Call prior',
            IUDRemoval: 'Call prior',
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/CarlyBabisMain.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/carly-babis-pa-c/',
        inNetwork: true,
        myChartID: '270075',
        locations: [locationTypes.HILLCREST.id, locationTypes.DOWNTOWN_SAN_DIEGO.id]
    },
    {
        firstName: 'Puneet',
        lastName: 'Bhalrhu',
        DEA: 'FB6062599',
        license: 'A158762',
        NPI:'1174962898',
        gender: 'female',
        ageLimitPrimary: 0,
        ageLimitUrgentCare: 0,
        language: {},
        specialInterests: ['Family Practice '],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: 'knee',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: 'Call prior',
            IUDRemoval: 'Call prior',
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/PuneetBhalrhu-1024x1024.jpg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/puneet-bhalrhu-md/',
        inNetwork: true,
        myChartID: '273037',
        locations: [locationTypes.HILLCREST.id, locationTypes.DOWNTOWN_SAN_DIEGO.id]
    },
    {   
        firstName:'Madalasa',
        lastName: 'Bista',
        DEA: 'FB6406044',
        license: 'A145628',
        NPI:'1174966444',
        gender: 'female',
        ageLimitPrimary: 1,
        ageLimitUrgentCare: 1,
        language: {nepali: true},
        specialInterests: ['Family Medicine'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: 'Hard copy prescriptions only',
            newPTAnnuals: true,
            joint: 'knee',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: true,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/10/DrBista500x500.jpg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/madalasa-bista-m-d/', 
        inNetwork: true,
        myChartID: '39702',
        locations: [locationTypes.LA_JOLLA_XIMED.id]
    },
    {   
        firstName:'Kelsey',
        lastName: 'Brangoccio',
        DEA: 'MB4574390',
        license: 'PA55679',
        NPI:'1296255477',
        gender: 'female',
        ageLimitPrimary: 3,
        ageLimitUrgentCare: 3,
        language: {},
        specialInterests: ['Preventive Health', 'lifestyle changes'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: true,
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: 'major joint',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: true,
            IUDRemoval: true,
            nexplanonPlacement: true,
            nexplanonRemoval: true,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/Kelsey500x500-1024x1024.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/kelsey-brangoccio-pa-c/', 
        inNetwork: true,
        myChartID: '267386',
        locations: [locationTypes.KENSINGTON.id]
    },
    {   
        firstName:'Edward',
        lastName: 'Brantz',
        DEA: 'AB8165195',
        license: 'A31523',
        NPI:'1124096656',
        gender: 'male',
        ageLimitPrimary: 5,
        ageLimitUrgentCare: 5,
        language: {spanish: true},
        specialInterests: ['Family Medicine'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: false,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/PC_provider2.jpg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/dr-edward-brantz/',
        inNetwork: true,
        myChartID: '06405',
        locations: [locationTypes.CLAIREMONT.id]
    },
    {   
        firstName:'Robert',
        lastName: 'Brunst',
        DEA: 'AB8167226',
        license: 'G36956',
        NPI:'1639257546',
        gender: 'male',
        ageLimitPrimary: 18,
        ageLimitUrgentCare: 18,
        language: {spanish: true, dutch: true, german: true},
        specialInterests: ['Internal Medicine'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: '',
            internalMedicine: true,
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: 'Hard copy presciptions only',
            newPTAnnuals: true,
            joint: 'major joint',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/12/RobertBrunst500x500.jpg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/robert-brunst-m-d/',
        inNetwork: true,
        myChartID: '08670',
        locations: [locationTypes.ENCINITAS.id]
    },
    {   
        firstName:'William',
        lastName: 'Buchner Jr.',
        DEA: 'FB1709344',
        license: 'A109750',
        NPI:'1356599443',
        gender: 'male',
        ageLimitPrimary: 5,
        ageLimitUrgentCare: 5,
        language: {},
        specialInterests: ['Integrative Medicine'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: true,
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: 'Hard copy prescriptions only',
            newPTAnnuals: false,
            joint: 'any',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/Dr.-Buchner-Main.jpg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/william-buchner-m-d/',
        inNetwork: true,
        myChartID: '60830',
        locations: [locationTypes.HILLCREST.id]
    },
    {
        firstName: 'Lauren',
        lastName: 'Campagna',
        DEA: 'MC3708724',
        license: 'PA52878',
        NPI:'1679946040',
        gender: 'female',
        ageLimitPrimary: 18,
        ageLimitUrgentCare: 4,
        language: {},
        specialInterests: ['Family Medicine', 'Aesthetic Medicine'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: true,
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: false,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/fp_milestone-create-1384638-1.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/lauren-campagna-pa/',
        inNetwork: true,
        myChartID: '40112',
        locations: [locationTypes.DOWNTOWN_SAN_DIEGO.id, locationTypes.LA_JOLLA_XIMED.id]
    },
    {   
        firstName:'Nicholas',
        lastName: 'Canelo',
        DEA: 'FC7399389',
        license: 'A145725',
        NPI:'1104209048',
        gender: 'male',
        ageLimitPrimary: 1,
        ageLimitUrgentCare: 1,
        language: {},
        specialInterests: ['Family Medicine', 'Integrative Medicine'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: true,
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: 'major joint',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: true,
            IUDRemoval: true,
            nexplanonPlacement: true,
            nexplanonRemoval: true,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/Canelo500x500-1-1.jpg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/nicholas-canelo-m-d/',
        inNetwork: true,
        myChartID: '65521',
        locations: [locationTypes.LA_JOLLA_DOWNTOWN.id]
    },
    {   
        firstName:'Erinn',
        lastName: 'Case',
        DEA: 'MC2691473',
        license: 'F95009488',
        NPI:'1477802833',
        gender: 'female',
        ageLimitPrimary: 0,
        ageLimitUrgentCare: 0,
        language: {},
        specialInterests: ['Family Medicine'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: 'any',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: true,
            IUDRemoval: true,
            nexplanonPlacement: true,
            nexplanonRemoval: true,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/ErinnCase500x500.jpg',
        title: 'NP',
        src: 'https://perlmanclinic.com/ourteam/erinn-case-fnp-c/',
        inNetwork: true,
        myChartID: '270607',
        locations: [locationTypes.CLAIREMONT.id]
    },
    {   
        firstName:'Stephen',
        lastName: 'Caso',
        DEA: 'MC2300628',
        license: 'PA21365',
        NPI:'1083916985',
        gender: 'male',
        ageLimitPrimary: 12,
        ageLimitUrgentCare: 12,
        language: {},
        specialInterests: ['Family Medicine'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: false,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/PC_provider3.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/stephen-caso-pa/',
        inNetwork: true,
        myChartID: '82085',
        locations: [locationTypes.LA_JOLLA_XIMED.id]
    },
    {   
        firstName:'Christopher',
        lastName: 'Ceriale',
        DEA: 'MC4490683',
        license: 'PA54651',
        NPI:'1255858221',
        gender: 'male',
        ageLimitPrimary: 18,
        ageLimitUrgentCare: 18,
        language: {},
        specialInterests: ['Reproductive health', 'PREP/ HIV/AIDS specialist'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
            reproductiveHealth: true,
        },
        care: {
            medicationRefills: 'Hard copy prescriptions only',
            newPTAnnuals: true,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: true,
            IUDRemoval: true,
            nexplanonPlacement: true,
            nexplanonRemoval: true,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/09/Chris500x500.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/christopher-ceriale-pa-c/',
        inNetwork: true,
        myChartID: '263353',
        locations: []
    },
    {   
        firstName:'Alonso',
        lastName: 'Chan',
        DEA: 'MC3044409',
        license: 'PA23195',
        NPI:'1831525286',
        gender: 'male',
        ageLimitPrimary: 0,
        ageLimitUrgentCare: 0,
        language: {spanish: true},
        specialInterests: ['Family Medicine', 'Emergency Medicine', 'Womens Health', 'Orthopedics'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: true,
            womensHealth: true,
            orthopedics: true,
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: 'majore joint',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: true,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/AlonsoChan.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/alonso-chan-pa-c/',
        inNetwork: true,
        myChartID: '84690',
        locations: [locationTypes.CHULA_VISTA.id]
    },
    {   
        firstName:'Alexandra',
        lastName: 'Chatfield',
        DEA: 'MC5455577',
        license: 'PA57107',
        NPI:'1215584628',
        gender: 'female',
        ageLimitPrimary: 0,
        ageLimitUrgentCare: 0,
        language: {},
        specialInterests: ['Internal Medicine'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: '',
            internalMedicine: true,
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: 'No controlled substances',
            newPTAnnuals: true,
            joint: 'knee',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: true,
            IUDRemoval: true,
            nexplanonPlacement: true,
            nexplanonRemoval: true,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/12/AlexandraChatfield500x500.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/alexandra-chatfield-pa-c/',
        inNetwork: true,
        myChartID: '278907',
        locations: []
    },
    {   
        firstName:'Gourab',
        lastName: 'Das',
        DEA: 'MD3201833',
        license: 'PA51621',
        NPI:'1487066965',
        gender: 'male',
        ageLimitPrimary: 18,
        ageLimitUrgentCare: 18,
        language: {hindi: true},
        specialInterests: ['Internal Medicine '],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: '',
            internalMedicine: true,
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: 'Hard copy prescriptions only',
            newPTAnnuals: true,
            joint: 'knee',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/12/GourabDas500x500.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/gourab-das-pa-c/',
        inNetwork: true,
        myChartID: '262694',
        locations: [locationTypes.ENCINITAS.id]
    },
    {   
        firstName:'Emily',
        lastName: 'Ence',
        DEA: 'ME3367302',
        license: 'PA52084',
        NPI:'1063784478',
        gender: 'female',
        ageLimitPrimary: 12,
        ageLimitUrgentCare: 12,
        language: {},
        specialInterests: ['Preventive Medicine', 'lifestyle changes'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: true,
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: true,
            nexplanonPlacement: true,
            nexplanonRemoval: true,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/PC_provider4-300x300.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/emily-ence-pa/',
        inNetwork: true,
        myChartID: '84615',
        locations: [locationTypes.HILLCREST.id]
    },
    {   
        firstName:'Neenah',
        lastName: 'Grade',
        DEA: 'MG3939432',
        license: 'PA53586',
        NPI:'1063865061',
        gender: 'female',
        ageLimitPrimary: 0,
        ageLimitUrgentCare: 0,
        language: {},
        specialInterests: ['Family Medicine', 'Emergency Medicine'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: true,
        },
        care: {
            medicationRefills: 'No controlled substances',
            newPTAnnuals: true,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: '',
        title: 'PA',
        src: '',
        inNetwork: true,
        myChartID: '270085',
        locations: [locationTypes.HILLCREST.id]
    },
    {   
        firstName:'Julie',
        lastName: 'Hoffman',
        DEA: 'MH3857060',
        license: 'PA55348',
        NPI:'1538534805',
        gender: 'female',
        ageLimitPrimary: 0,
        ageLimitUrgentCare: 0,
        language: {},
        specialInterests: ['Peventative Medicine', 'lifestyle changes', 'patient education'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: true,
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: false,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: true,
            nexplanonRemoval: true,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/JulieHoffmanPhoto.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/julie-hoffman-pa-c/',
        inNetwork: true,
        myChartID: '267633',
        locations: [locationTypes.HILLCREST.id]
    },
    {   
        firstName:'Nicholas',
        lastName: 'Kahl',
        DEA: 'MK2254035',
        license: 'F95006360',
        NPI:'1821306598',
        gender: 'male',
        ageLimitPrimary: 0,
        ageLimitUrgentCare: 0,
        language: {},
        specialInterests: ['Family Medicine'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: 'knee, shoulder',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: true,
            nexplanonPlacement: true,
            nexplanonRemoval: true,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/12/NicholasKahl500x500.jpg',
        title: 'NP',
        src: 'https://perlmanclinic.com/ourteam/nicholas-kahl-fnp-c/',
        inNetwork: true,
        myChartID: '98164',
        locations: [locationTypes.DEL_MAR.id]
    },
    {   
        firstName:'Stephanie',
        lastName: 'Klein',
        DEA: 'MK4147965',
        license: 'PA53918',
        NPI:'1083151674',
        gender: 'female',
        ageLimitPrimary: 5,
        ageLimitUrgentCare: 5,
        language: {},
        specialInterests: ['Family Medicine', 'HIV/AIDS/PREP', 'Hormone therapy'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
            reproductiveHealth: true,
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/StephanieKleinPHOTO-1.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/stephanie-klein-pa-c/',
        inNetwork: true,
        myChartID: '257289',
        locations: [locationTypes.CARLSBAD.id]
    },
    {   
        firstName:'Natalia',
        lastName: 'Kovaleski',
        DEA: 'MK2971530',
        license: 'PA22994',
        NPI:'1841611324',
        gender: 'female',
        ageLimitPrimary: 18,
        ageLimitUrgentCare: 0,
        language: {spanish: true},
        specialInterests: ['Internal Medicine', 'chronic care management', 'preventive health'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: true,
            internalMedicine: true,
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
            chronicCare: true,
        },
        care: {
            medicationRefills: 'Hard copy prescriptions only',
            newPTAnnuals: true,
            joint: 'major joint',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/11/Natalia500x500.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/natalia-kovaleski-pa-c/',
        inNetwork: true,
        myChartID: '278112',
        locations: [locationTypes.DEL_MAR.id]
    },
    {   
        firstName:'Ilan',
        lastName: 'Kuperman',
        DEA: 'BK6653136',
        license: 'A87018',
        NPI:'1336178169',
        gender: 'male',
        ageLimitPrimary: 18,
        ageLimitUrgentCare: 18,
        language: {spanish: true},
        specialInterests: ['Integrative Medicine'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: true,
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: false,
            joint: '',
            suturePlacement: false,
            sutureRemoval: false,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: ' https://perlmanclinic.com/wp-content/uploads/2019/06/Ilan-Kuperman-Final-500-by-500.jpg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/ilan-kuperman-md/',
        inNetwork: true,
        myChartID: '16808',
        locations: [locationTypes.LA_JOLLA_XIMED.id]
    },
    {   
        firstName:'Melissa',
        lastName: 'Macy',
        DEA: 'FM3980693',
        license: '20A12830',
        NPI:'1326488719',
        gender: 'female',
        ageLimitPrimary: 5,
        ageLimitUrgentCare: 5,
        language: {},
        specialInterests: ['Osteopathic Manipulation', 'Family Medicine', 'Orthopedics'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: true,
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
            orthopedics: true,
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: false,
            joint: 'any',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/Dr.Macy500x500.jpg',
        title: 'DO',
        src: 'https://perlmanclinic.com/ourteam/melissa-macy-do/',
        inNetwork: true,
        myChartID: '82466',
        locations: [locationTypes.DOWNTOWN_SAN_DIEGO.id, locationTypes.CLAIREMONT.id]
    },
    {   
        firstName:'Paul',
        lastName: 'Marquis',
        DEA: 'BM1144435',
        license: 'G161976',
        NPI:'1407806151',
        gender: 'male',
        ageLimitPrimary: 0,
        ageLimitUrgentCare: 0,
        language: {},
        specialInterests: ['Family Medicine'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: 'Hard copy prescriptions only',
            newPTAnnuals: true,
            joint: 'major joint',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2020/01/Marquis500x500.jpeg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/paul-marquis-m-d/',
        inNetwork: true,
        myChartID: '279810',
        locations: [locationTypes.LA_MESA.id]
    },
    {   
        firstName:'ChristyAnne',
        lastName: 'Maxson',
        DEA: 'FM6151295',
        license: 'C51943',
        NPI:'1750897500',
        gender: 'female',
        ageLimitPrimary: 0,
        ageLimitUrgentCare: 0,
        language: {},
        specialInterests: ['Womens Health', 'Young Families'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
            womensHealth: true,
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: false,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: true,
            IUDRemoval: true,
            nexplanonPlacement: true,
            nexplanonRemoval: true,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/ChristyanneMaxsonPhoto.jpg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/christyanne-maxson-m-d/',
        inNetwork: true,
        myChartID: '98016',
        locations: [locationTypes.LA_JOLLA_XIMED.id, locationTypes.DEL_MAR.id]
    },
    {   
        firstName:'Brenna',
        lastName: 'Memole',
        DEA: 'MM4794372',
        license: 'F95008681',
        NPI:'1437698677',
        gender: 'female',
        ageLimitPrimary: 18,
        ageLimitUrgentCare: 18,
        language: {},
        specialInterests: ['Gerontology', 'lifestyle modifications', 'weightloss', 'nutrition', 'preventive health'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: true,
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
            gerontology: true,
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: '',
            suturePlacement: false,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/BrennaMemole.jpg',
        title: 'NP',
        src: 'https://perlmanclinic.com/ourteam/brenna-memole-agnp-c/',
        inNetwork: true,
        myChartID: '269350',
        locations: [locationTypes.LA_JOLLA_XIMED.id]
    },
    {   
        firstName:'Nicole',
        lastName: 'Michael',
        DEA: 'MM5569453',
        license: 'F95011609',
        NPI:'1831759315',
        gender: 'female',
        ageLimitPrimary: 16,
        ageLimitUrgentCare: 16,
        language: {},
        specialInterests: ['Family Medicine', 'Emergency Medicine'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: true,
        },
        care: {
            medicationRefills: 'Hard copy prescriptions only',
            newPTAnnuals: true,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2020/02/NicoleMichael500x500.jpg',
        title: 'NP',
        src: 'https://perlmanclinic.com/ourteam/nicole-michael/',
        inNetwork: true,
        myChartID: '280127',
        locations: [locationTypes.DEL_MAR.id, locationTypes.KENSINGTON.id, locationTypes.HILLCREST.id]
    },
    {   
        firstName:'Kristen',
        lastName: 'Mott',
        DEA: 'MM5695208',
        license: 'PA57165',
        NPI:'1790339828',
        gender: 'female',
        ageLimitPrimary: 5,
        ageLimitUrgentCare: 5,
        language: {},
        specialInterests: ['Family Medicine'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: 'Hard copy prescriptions only',
            newPTAnnuals: true,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2020/02/KristenMott500x500.jpeg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/kristen-mott-pa-c/',
        inNetwork: true,
        myChartID: '282508',
        locations: []
    },
    {   
        firstName:'Seymour',
        lastName: 'Myers',
        DEA: 'AM7746881',
        license: 'G35184',
        NPI:'1083657571',
        gender: 'male',
        ageLimitPrimary: 18,
        ageLimitUrgentCare: 18,
        language: {},
        specialInterests: ['Family Medicine', 'hospice', 'end of life planning'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: true,
            emergencyMedicine: '',
            hospice: true,
            gerontology: true,
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: false,
            joint: '',
            suturePlacement: false,
            sutureRemoval: false,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/dr-3-perlman-clinic.jpg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/seymour-myers-m-d/',
        inNetwork: true,
        myChartID: '05073',
        locations: [locationTypes.CARLSBAD.id]
    },
    {   
        firstName:'Brooke',
        lastName: 'Myrberg',
        DEA: 'MM5184267',
        license: 'PA56234',
        NPI:'1295206381',
        gender: 'female',
        ageLimitPrimary: 18,
        ageLimitUrgentCare: 10,
        language: {},
        specialInterests: ['Family Medicine'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: true,
            nexplanonRemoval: true,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/BrookeMyrberg500x500.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/brooke-myrberg-pa-c/',
        inNetwork: true,
        myChartID: '271056',
        locations: [locationTypes.HILLCREST.id, locationTypes.KENSINGTON.id]
    },
    {   
        firstName:'Kenneth',
        lastName: 'Ortiz',
        DEA: 'FO7664635',
        license: 'A156607',
        NPI:'1356761571',
        gender: 'male',
        ageLimitPrimary: 0,
        ageLimitUrgentCare: 0,
        language: {pidgin: true},
        specialInterests: ['Aesthetic Medicine', 'Geriatrics'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: true,
            geriatricMedicine: true,
            emergencyMedicine: '',
            reproductiveHealth: true,
            gerontology: true,
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: 'major joint',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: true,
            IUDRemoval: true,
            nexplanonPlacement: true,
            nexplanonRemoval: true,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/KennethOrtizMD.jpg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/kenneth-ortiz-m-d/',
        inNetwork: true,
        myChartID: '270059',
        locations: [locationTypes.HILLCREST.id, locationTypes.DOWNTOWN_SAN_DIEGO.id]
    },
    {   
        firstName: 'Emilia',
        lastName: 'Parrott',
        DEA: 'FS4640214',
        license: '20A16409',
        NPI:'1114272473',
        gender: 'female',
        ageLimitPrimary: 0,
        ageLimitUrgentCare: 0,
        language: {polish: true},
        specialInterests: ['Family Medicine', 'HIV/AIDS'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
            reproductiveHealth: true,
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: 'any',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: true,
            IUDRemoval: true,
            nexplanonPlacement: true,
            nexplanonRemoval: true,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/DrParrott.jpg',
        title: 'DO',
        src: 'https://perlmanclinic.com/ourteam/emilia-parrott-do/',
        inNetwork: true,
        myChartID: '270103',
        locations: [locationTypes.KENSINGTON.id]
    },
    {   
        firstName:'Monica',
        lastName: 'Perlman',
        DEA: 'BP2530447',
        license: 'G70356',
        NPI:'1780796235',
        gender: 'female',
        ageLimitPrimary: 13,
        ageLimitUrgentCare: 13,
        language: {spanish: true},
        specialInterests: ['Internal Medicine', 'Preventive Medicine', 'Aestheic Medicine'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: true,
            internalMedicine: true,
            integrativeMedicine: '',
            aestheticMedicine: true,
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/PC_provider9.jpg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/dr-monica-perlman/',
        inNetwork: true,
        myChartID: '08899',
        locations: [locationTypes.LA_JOLLA_XIMED.id, locationTypes.LA_JOLLA_DOWNTOWN.id]
    },
    {   
        firstName:'Jennifer',
        lastName: 'Phan',
        DEA: 'MP4780789',
        license: 'F95008319',
        NPI:'1609372150',
        gender: 'female',
        ageLimitPrimary: 18,
        ageLimitUrgentCare: 18,
        language: {vietnamese: true, spanish: true },
        specialInterests: ['Internal Medicine', 'Emergency Medicine'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: '',
            internalMedicine: true,
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: true,
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/09/JenniferP500x500.jpg',
        title: 'NP',
        src: 'https://perlmanclinic.com/ourteam/jennifer-phan-fnp-c/',
        inNetwork: true,
        myChartID: '34455',
        locations: []
    },
    {   
        firstName:'Sarah',
        lastName: 'Pierce',
        DEA: 'MP4817687',
        license: 'F95008449',
        NPI:'1518455179',
        gender: 'female',
        ageLimitPrimary: 0,
        ageLimitUrgentCare: 0,
        language: {},
        specialInterests: ['Preventive Medicine'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: true,
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: '',
            suturePlacement: false,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/Sarah-Pierce.jpg',
        title: 'NP',
        src: 'https://perlmanclinic.com/ourteam/sarah-pierce-fnp-c/',
        inNetwork: true,
        myChartID: '35697',
        locations: []
    },
    {   
        firstName:'William',
        lastName: 'Pitt',
        DEA: 'AP5089861',
        license: 'A23081',
        NPI:'1861544850',
        gender: 'male',
        ageLimitPrimary: 18,
        ageLimitUrgentCare: 18,
        language: {},
        specialInterests: ['Interventional Cardiology', 'Internal Medicine'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: true,
            internalMedicine: true,
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: 'Hard copy prescriptions only',
            newPTAnnuals: true,
            joint: 'major joint',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: '',
        title: 'MD',
        src: '',
        inNetwork: true,
        myChartID: '01975',
        locations: [locationTypes.LA_MESA.id]
    },
    {   
        firstName:'Mirza',
        lastName: 'Raheem',
        DEA: 'AR7353042',
        license: 'C140215',
        NPI:'1609886647',
        gender: 'male',
        ageLimitPrimary: 16,
        ageLimitUrgentCare: 16,
        language: {},
        specialInterests: ['Internal Medicine'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: '',
            internalMedicine: true,
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: 'Hard copy prescriptions only',
            newPTAnnuals: true,
            joint: '',
            suturePlacement: false,
            sutureRemoval: false,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2020/01/Raheem500x500.jpeg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/mirza-raheem-m-d/',
        inNetwork: true,
        myChartID: '279880',
        locations: [locationTypes.LA_JOLLA_XIMED.id]
    },
    {   
        firstName:'Georgina',
        lastName: 'Rai',
        DEA: 'MR4564705',
        license: 'PA54629',
        NPI:'1467974915',
        gender: 'female',
        ageLimitPrimary: 16,
        ageLimitUrgentCare: 16,
        language: {},
        specialInterests: ['Preventive Medicine', 'Womens Health', 'PREP', 'Patient Education'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: true,
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
            womensHealth: true,
            reproductiveHealth: true,
        },
        care: {
            medicationRefills: 'Hard copy prescriptions only',
            newPTAnnuals: true,
            joint: 'any',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: true,
            IUDRemoval: true,
            nexplanonPlacement: true,
            nexplanonRemoval: true,
        },
        img: '',
        title: 'PA',
        src: '',
        inNetwork: true,
        myChartID: '263319',
        locations: []
    },
    {   
        firstName:'Philippa',
        lastName: 'Rai',
        DEA: 'MR5563615',
        license: 'PA56698',
        NPI:'1174083836',
        gender: 'female',
        ageLimitPrimary: 10,
        ageLimitUrgentCare: 10,
        language: {},
        specialInterests: ['Preventive Medicine', 'Womens Health', 'mental health', 'laceration repair', 'in office procedures'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: true,
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
            womensHealth: true,
            mentalHealth: true,
        },
        care: {
            medicationRefills: 'Hard copy prescriptions only',
            newPTAnnuals: false,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/Pippa500by500-1024x1024.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/philippa-rai-pa-c/',
        inNetwork: true,
        myChartID: '272253',
        locations: []
    },
    {   
        firstName:'Jessica',
        lastName: 'Sarder',
        DEA: 'MS4543092',
        license: 'PA55016',
        NPI:'1083124564',
        gender: 'female',
        ageLimitPrimary: 18,
        ageLimitUrgentCare: 18,
        language: {spanish: true},
        specialInterests: ['Internal Medicine', 'Emergency Medicine', 'PREP/HIV/AIDS'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: '',
            internalMedicine: true,
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: true,
            reproductiveHealth: true,
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/JessicaSarderPhoto.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/jessica-sarder-pa-c/',
        inNetwork: true,
        myChartID: '97632',
        locations: []
    },
    {   
        firstName:'Adam',
        lastName: 'Shults',
        DEA: 'MS5450503',
        license: 'PA57151',
        NPI:'1093088205',
        gender: 'male',
        ageLimitPrimary: 1,
        ageLimitUrgentCare: 1,
        language: {},
        specialInterests: ['Family Medicine', 'Emergency Medicine', 'PREP/HIV/AIDS'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: true,
            reproductiveHealth: true,
        },
        care: {
            medicationRefills: 'Hard copy prescriptions only',
            newPTAnnuals: true,
            joint: 'major joint',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/10/Adam500x500.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/adam-shults-pa-c/',
        inNetwork: true,
        myChartID: '278020',
        locations: []
    },
    {   
        firstName:'Kelly',
        lastName: 'Snowden',
        DEA: 'MS4993374',
        license: 'PA55980',
        NPI:'1770065252',
        gender: 'female',
        ageLimitPrimary: 0,
        ageLimitUrgentCare: 0,
        language: {},
        specialInterests: ['Preventive Medicine', 'Emergency Medicine'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: true,
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: true,
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: true,
            IUDRemoval: true,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/KellySnowden.jpg',
        title: 'PA',
        src: 'https://perlmanclinic.com/ourteam/kelly-snowden-pa-c/',
        inNetwork: true,
        myChartID: '270097',
        locations: [locationTypes.LA_JOLLA_DOWNTOWN.id]
    },
    {   
        firstName:'Youstina',
        lastName: 'Soliman',
        DEA: 'FS4807953',
        license: 'C160848',
        NPI:'1497036560',
        gender: 'female',
        ageLimitPrimary: 18,
        ageLimitUrgentCare: 12,
        language: {arabic: true},
        specialInterests: ['Family Medicine'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: 'Hard copy prescriptions only',
            newPTAnnuals: true,
            joint: 'major joint',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: true,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/11/DrSoliman500x500.jpg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/youstina-soliman-m-d/',
        inNetwork: true,
        myChartID: '277986',
        locations: [locationTypes.DEL_MAR.id]
    },
    {   
        firstName:'Anna',
        lastName: 'Stern',
        DEA: 'BS8969000',
        license: 'A88573',
        NPI:'1184728834',
        gender: 'female',
        ageLimitPrimary: 16,
        ageLimitUrgentCare: 16,
        language: {},
        specialInterests: ['Public Health', 'Preventative Medicine'],
        specialty: {
            familyMedicine: '',
            preventativeMedicine: true,
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: false,
            joint: '',
            suturePlacement: false,
            sutureRemoval: false,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/annastern-jessica.jpg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/anna-stern-m-d/',
        inNetwork: true,
        myChartID: '51404',
        locations: [locationTypes.LA_JOLLA_XIMED.id]
    },
    {   
        firstName:'Jamie',
        lastName: 'Switzer',
        DEA: 'BS2681852',
        license: '20A6331',
        NPI:'1972513000',
        gender: 'female',
        ageLimitPrimary: 14,
        ageLimitUrgentCare: 14,
        language: {},
        specialInterests: ['Family Medicine'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: false,
            joint: 'major joint',
            suturePlacement: false,
            sutureRemoval: false,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/PC_provider61.jpg',
        title: 'DO',
        src: 'https://perlmanclinic.com/ourteam/dr-jamie-switzer/',
        inNetwork: true,
        myChartID: '14436',
        locations: [locationTypes.DEL_MAR.id, locationTypes.LA_JOLLA_XIMED.id]
    },
    {   
        firstName:'Susan',
        lastName: 'Taylor',
        DEA: 'AT3224122',
        license: 'G55574',
        NPI:'1205872827',
        gender: 'female',
        ageLimitPrimary: 3,
        ageLimitUrgentCare: 3,
        language: {},
        specialInterests: ['Family Medicine'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: '',
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: false,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: false,
            IUDRemoval: false,
            nexplanonPlacement: true,
            nexplanonRemoval: true,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/Susan-Taylor-MD-Main-Jessica.jpg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/susan-taylor-m-d/',
        inNetwork: true,
        myChartID: '257284',
        locations: [locationTypes.KENSINGTON.id]
    },
    {   
        firstName:'Rhodaline',
        lastName: 'Tootell',
        DEA: 'FT0481109',
        license: 'C160215',
        NPI:'1063547867',
        gender: 'female',
        ageLimitPrimary: 0,
        ageLimitUrgentCare: 0,
        language: {tagolog: true},
        specialInterests: ['Preventive Medicine', 'chronic conditions', 'womens health', 'pediatrics'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: true,
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
            womensHealth: true,
            chronicCare: true,
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: true,
            joint: '',
            suturePlacement: false,
            sutureRemoval: false,
            IUDPlacement: true,
            IUDRemoval: true,
            nexplanonPlacement: false,
            nexplanonRemoval: false,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/RhodalineTootell500x500MAIN.jpg',
        title: 'MD',
        src: 'https://perlmanclinic.com/ourteam/rhodaline-tootell-m-d/',
        inNetwork: true,
        myChartID: '272234',
        locations: [locationTypes.CARLSBAD.id, locationTypes.LA_JOLLA_XIMED.id]
    },
    {   
        firstName:'Yuliya',
        lastName: 'Yartseva',
        DEA: 'MY4678693',
        license: 'F95007558',
        NPI:'1750881843',
        gender: 'female',
        ageLimitPrimary: 16,
        ageLimitUrgentCare: 16,
        language: {russian: true},
        specialInterests: ['Preventive Medicine', 'Family Medicine'],
        specialty: {
            familyMedicine: true,
            preventativeMedicine: true,
            internalMedicine: '',
            integrativeMedicine: '',
            aestheticMedicine: '',
            geriatricMedicine: '',
            emergencyMedicine: '',
        },
        care: {
            medicationRefills: '',
            newPTAnnuals: false,
            joint: '',
            suturePlacement: true,
            sutureRemoval: true,
            IUDPlacement: true,
            IUDRemoval: true,
            nexplanonPlacement: true,
            nexplanonRemoval: true,
        },
        img: 'https://perlmanclinic.com/wp-content/uploads/2019/06/Yuliya-PA-C-1.jpg',
        title: 'NP',
        src: 'https://perlmanclinic.com/ourteam/yuliya-yartseva-fnp-c/',
        inNetwork: true,
        myChartID: '263890',
        locations: [locationTypes.CLAIREMONT.id]
    }

];

export default PrimaryCareProviders;